import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, mergeMap, catchError, tap } from 'rxjs/operators';

// import * as action from './index';
import * as authActions from './auth.actions';

// Service
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthEffects {
  constructor(private actions$: Actions,
              private _authService: AuthService) {}

  login$ = createEffect(() => this.actions$.pipe(
    ofType(authActions.login),
    mergeMap((action) => this._authService.login(action.user.username, action.user.password)
      .pipe(
        map(user => authActions.loginSuccess({ userLoggedIn: user })),
        catchError((err) => of(authActions.loginError({ error: err.error })))
      ))
  ));
}
