import { Injectable } from '@angular/core';

export class Error400RequestModel {
 property: string;
 children: Error400RequestModel[];
 constraints?: {
   maxLength?: string;
   isNumber?: string;
   minLength?: string;
   matches?: string;
 };
}

export class Error400ResponseModel {
  property: string;
  errorMessage: string;
 }

@Injectable()
export class CatchError400Service {

  catchError400(messageResponse: Error400RequestModel[]): Error400ResponseModel[] {
    const errors: Error400ResponseModel[] = [];

    messageResponse.forEach(errorFound => {
      const property = errorFound.property;
      let childProperty: string;
      let errorMessage: string;

      // Here we detect if error response has children or not
      if (errorFound.constraints) {
        errorMessage = errorFound.constraints[Object.keys(errorFound.constraints)[0]];

        const errorObject: Error400ResponseModel = {
          property,
          errorMessage
        };
        errors.push(errorObject);
      } else {
        errorFound.children.forEach(childErrorFound => {
          childProperty = childErrorFound.property;
          errorMessage = childErrorFound.constraints[Object.keys(childErrorFound.constraints)[0]];
          const concatenetedProperty = `${property}.${childProperty}`;

          const errorObject: Error400ResponseModel = {
            property: concatenetedProperty,
            errorMessage
          };

          errors.push(errorObject);
        });
      }
    });
    return errors;
  }
}
