import { Injectable } from '@angular/core';
import { CanLoad } from '@angular/router';
import { Router } from '@angular/router';

// Services
import { AuthService } from '../services/auth.service';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

// Store
import { Store } from '@ngrx/store';
import { AppState } from '../../app.interface';
import * as catalogsActions from '../../catalogs/store/catalogs.actions'

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanLoad {

  constructor(private _authService: AuthService,
              private router: Router,
              private store: Store<AppState>) {}

  canLoad(): Observable<any> {
    const catalogs$ = this.store.select('catalogs');
    const isAuthWithCatalogs$: Observable<boolean> = new Observable<boolean>(observer => {
      if (!this._authService.isAuth) {
        if (this._authService.getToken() && this._authService.getUserInfo()) {
          catalogs$.subscribe(catalogResponse => {
            if (catalogResponse.statusCode) {
              observer.next(true);
              observer.complete();
            }
          });
          this.store.dispatch(catalogsActions.catalogsRequest());

        } else {
          this.router.navigate(['/login']);
          observer.next(false);
          observer.complete();
        }
      } else {
        observer.next(true);
        observer.complete();
      }
    });
    return isAuthWithCatalogs$;
  }
}
