import { Component, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';

interface AppNav {
  name: string;
  url?: string;
  children?: AppNav[];
}



const TREE_DATA: AppNav[] = [
  {
    name: 'Dashboard',
    children: [
      {name: 'Por definir'},
      {name: 'Por definir'},
      {name: 'Por definir'},
    ]
  },
  {
    name: 'Cuentas',
    children: [
      {
        name: 'Listado de Cuentas',
        url: '/accounts'
      },
      {
        name: 'Listado Contactos',
        url: '/accounts/contacts'
      },
      {
        name: 'Listado de Eventos',
        url: 'accounts/events'
      },
    ]
  },
  {
    name: 'Propuestas',
    children: [
      {name: 'Por definir'},
      {name: 'Por definir'},
      {name: 'Por definir'},
    ]
  },
  {
    name: 'Proyectos',
    children: [
      {name: 'Por definir'},
      {name: 'Por definir'},
      {name: 'Por definir'},
    ]
  },
  {
    name: 'Configuraciones',
    children: [
      {
        name: 'Mi Cuenta',
        url: '/my-account'
      },
      {
        name: 'Usuarios',
        url: '/users'
      },
      {
        name: 'Modelos del Sistema',
        url: '/catalogs'
      },
    ]
  },
  // {
  //   name: 'Cuentas',
  //   children: [
  //     {
  //       name: 'Green',
  //       children: [
  //         {name: 'Broccoli'},
  //         {name: 'Brussels sprouts'},
  //       ]
  //     }, {
  //       name: 'Orange',
  //       children: [
  //         {name: 'Pumpkins'},
  //         {name: 'Carrots'},
  //       ]
  //     },
  //   ]
  // },
];

@Component({
  selector: 'side-nav',
  templateUrl: 'side-nav.component.html',
  styleUrls: ['side-nav.component.scss']
})

export class SideNavComponent {

  @Output() closeSidenavEvent: EventEmitter<any> = new EventEmitter<any>();
  treeControl = new NestedTreeControl<AppNav>(node => node.children);
  dataSource = new MatTreeNestedDataSource<AppNav>();

  constructor(private router: Router) {
    this.dataSource.data = TREE_DATA;
  }

  get user() {
    const localS = localStorage.getItem('user');
    const userInfo = JSON.parse(localS);
    return userInfo;
  }

  navigateTo(url: string) {
    this.router.navigate([url]);
    this.closeSidenavEvent.emit();
  }

  logout() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('user');
    this.router.navigate(['/login']);
  }

  hasChild = (_: number, node: AppNav) => !!node.children && node.children.length > 0;
}
