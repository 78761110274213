<div class="side-nav">
  <div class="side-nav-header">
    <img [src]="user?.profile_img" class="profile-img">
    <p class="user-name">{{ user?.name | titlecase }} {{ user?.lastname | titlecase }}</p>
  </div>
  <div class="side-nav-body">
    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="side-nav-tree">
      <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
        <li class="mat-tree-node" (click)="navigateTo(node.url)">
          
          <button mat-icon-button disabled></button>
          {{node.name}}
        </li>
      </mat-tree-node>
      <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
        <li matTreeNodeToggle [attr.aria-label]="'toggle ' + node.name">
          <div class="mat-tree-node">
            <button mat-icon-button >
              <mat-icon class="mat-icon-rtl-mirror">
                {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
              </mat-icon>
            </button>
            {{node.name}}
          </div>
          <ul [class.side-nav-tree-invisible]="!treeControl.isExpanded(node)">
            <ng-container matTreeNodeOutlet></ng-container>
          </ul>
        </li>
      </mat-nested-tree-node>
    </mat-tree>
  </div>
  <div class="side-nav-footer">
    <button class="primary-btn" (click)="logout()">Logout</button>
  </div>
</div>