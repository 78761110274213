import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, RouterEvent, RoutesRecognized } from '@angular/router';

// Store
import { Store } from '@ngrx/store';
import { AppState } from './app.interface';
import * as catalogsActions from './catalogs/store/catalogs.actions';

// Services
import { AuthService } from './auth/services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(private router: Router,
              private store: Store<AppState>,
              private _authService: AuthService) {}

  @ViewChild('sidenav') sidenav;

  componentTitle: string;
  isSidenavCloseDisabled = false;
  isSidenavOpen = true;

  isLoginRoute: boolean = false;

  ngOnInit() {
    this.routerSubscriber();
  }

  routerSubscriber() {
    this.router.events
    .subscribe((res: RouterEvent) => {
      if (res instanceof RoutesRecognized) {
        this.isLoginRoute = (res.url === '/login') ? true : false;
        this.getComponentTitle(res.url);
      }
    });
  }

  getComponentTitle(url: string) {
    switch (url) {
      case '/users':
        this.componentTitle = 'Configuraciones';
        break;
      case '/my-account':
        this.componentTitle = 'Configuraciones';
        break;
      case '/catalogs':
        this.componentTitle = 'Configuraciones';
        break;
      case '/accounts':
        this.componentTitle = 'Cuentas';
        break;
      case '/accounts/contacts':
        this.componentTitle = 'Contactos';
        break;
      case '/accounts/events':
        this.componentTitle = 'Eventos';
        break;
      default:
        this.componentTitle = 'Sin Titulo';
    }
  }

  getSideNavMode() {
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    if (width >= 992) {
        this.isSidenavOpen = true;
        return 'side';
    } else {
        this.isSidenavOpen = false;
        return 'over';
    }
  }

  closeSideNav() {
    if (this.getSideNavMode() === 'over') {
      this.sidenav.close();
    }
  }

  get isAuth(): boolean {
    return this._authService.isAuth;
  }

  getActualUrl(actualUrl) {
    return actualUrl;
  }

  // catalogsRefresh(actualUrl) {
  //   console.log('entra catalogsRefresh')
  //   if (this._authService.getToken() && this._authService.getUserInfo()) {
  //     console.log('actual url en catalogs refresh', actualUrl);
  //     this.store.dispatch(catalogsActions.catalogsRequest());
  //   }
  // }
}
