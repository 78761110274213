import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, pipe } from 'rxjs';
import { map, mergeMap, catchError, tap } from 'rxjs/operators';

// import * as action from './index';
import * as userActions from './users-list.actions';

// Service
import { UsersListService } from '../../services/users-list.service';

@Injectable()
export class UsersListEffects {
  constructor(private actions$: Actions,
              private _usersListService: UsersListService) {}

  getUsers$ = createEffect(() => this.actions$.pipe(
    ofType(userActions.usersRequest),
    mergeMap(({userListRequest}) => this._usersListService.getUsers(userListRequest.page, userListRequest.limit, userListRequest.dept, userListRequest.position, userListRequest.name)
      .pipe(
        map(users => userActions.usersSuccess({ usersResponse: users })),
        catchError((err) => of(userActions.usersError({ error: err.error })))
      ))
  ));

  updateUser$ = createEffect(() => this.actions$.pipe(
    ofType(userActions.userUpdateActiveRequest),
    mergeMap(({userUpdateActiveRequest}) => this._usersListService.updateUser(userUpdateActiveRequest)
      .pipe(
        map(user => userActions.userUpdateActiveSuccess({ userResponse: user })),
        catchError((err) => of(userActions.userUpdateActiveError({ error: err.error })))
      ))
  ));

  createUser$ = createEffect(() => this.actions$.pipe(
    ofType(userActions.userCreateRequest),
    mergeMap(({userCreateRequest}) => this._usersListService.createUser(userCreateRequest)
      .pipe(
        map(user => userActions.userCreateSuccess({ userResponse: user })),
        catchError((err) => of(userActions.userCreateError({ userResponse: err.error })))
      ))
  ));

  usersListViewState$ = createEffect(() => this.actions$.pipe(
    ofType(userActions.usersListView),
    mergeMap(({usersListViewRequest}) => of(usersListViewRequest)
      .pipe(
        map(users => userActions.usersListViewSuccess({ usersListView: users })),
        catchError((err) => of(userActions.usersError({ error: err.error })))
      ))
  ));
}
