import { createAction, props } from '@ngrx/store';

// Models
import { ApiResponseModel } from 'crm-shared/models/api-response/api-response.model';
import { ContactsDetailResponseModel } from 'crm-shared/models/accounts/contacts/contactsDetail.model';

type id = string;

// Get ContactsDetail
export const contactsDetailRequest = createAction('[Contacts Detail] Contacts Detail Request', props<{ contactsDetailRequest: id }>());
export const contactsDetailSuccess = createAction('[Contacts Detail] Contacts Detail Success', props<{ contactsDetailResponse: ApiResponseModel<ContactsDetailResponseModel> }>());
export const contactsDetailError = createAction('[Contacts Detail] Contacts Detail Error', props<{ contactsDetailResponse: ApiResponseModel<any> }>());

