import { NgModule } from '@angular/core';

// Components
import { GlobalSpinnerComponent } from './global-spinner.component';
// import { SpinnerComponent } from '../spinner.component';
import { SpinnerModule } from '../spinner.module';

@NgModule({
  declarations: [
    GlobalSpinnerComponent,
  ],
  exports: [
    GlobalSpinnerComponent,
  ],
  imports: [
    SpinnerModule
  ]
})

export class GlobalSpinnerModule {}
