import { createAction, props } from '@ngrx/store';

// Models
import { ApiResponseModel } from 'crm-shared/models/api-response/api-response.model';
import { UserResponseModel } from 'crm-shared/models/user/user.model';
import { UserUpdateRequestModel } from 'crm-shared/models/user/user-update.model';

class UserDetailRequestModel {
  id: string;
}

// Get UsersDetail
export const userDetailRequest = createAction('[User Detail] User Detail Request', props<{ userDetailRequest: UserDetailRequestModel }>());
export const userDetailSuccess = createAction('[User Detail] User Detail Success', props<{ userDetailResponse: ApiResponseModel<UserResponseModel> }>());
export const userDetailError = createAction('[User Detail] User Detail Error', props<{ userDetailResponse: ApiResponseModel<UserResponseModel> }>());

// Update UsersDetail
export const userDetailUpdateRequest = createAction('[User Detail Update] User Detail Update Request', props<{ userDetailUpdateRequest: UserUpdateRequestModel }>());
export const userDetailUpdateSuccess = createAction('[User Detail Update] User Detail Update Success', props<{ userDetailUpdateResponse: ApiResponseModel<UserResponseModel> }>());
export const userDetailUpdateError = createAction('[User Detail Update] User Detail Update Error', props<{ userDetailUpdateResponse: ApiResponseModel<UserResponseModel> }>());
