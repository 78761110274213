import { createAction, props } from '@ngrx/store';

// Models
import { ApiResponseModel } from 'crm-shared/models/api-response/api-response.model';
import { ContactsListResponseModel, ContactsListRequestModel } from 'crm-shared/models/accounts/contacts/contactsList.model';
// import { UserResponseModel } from 'crm-shared/models/user/user.model';
// import { UserUpdateRequestModel } from 'crm-shared/models/user/user-update.model';
// import { UserCreateRequestModel } from 'crm-shared/models/user/user-create.model';

// Front End Models
// import { UsersListViewState } from '../../../shared/front-end-models/users-list-view.model';

// Filters
// class UserListRequestModel {
//   page: string;
//   limit: string;
//   dept: string;
//   position: string;
//   name: string;
// }



// Get ContactsList
export const contactsListRequest = createAction('[Contacts List] Contacts List Request', props<{ contactsListRequest: ContactsListRequestModel }>());
export const contactsListSuccess = createAction('[Contacts List] Contacts List Success', props<{ contactsListResponse: ApiResponseModel<ContactsListResponseModel[]> }>());
export const contactsListError = createAction('[Contacts List] Contacts List Error', props<{ contactsListResponse: ApiResponseModel<any> }>());
export const contactsListRefresh = createAction('[Contacts List] Contacts List Refresh');

