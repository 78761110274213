import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Components
import { SideNavComponent } from './side-nav.component';

// Modules
import { MaterialModule } from '../shared/material/material.module';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule
  ],
  declarations: [
    SideNavComponent
  ],
  exports: [
    SideNavComponent
  ]
})

export class SideNavModule {}
