import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { appReducers } from './app.reducers';

// Modules
import { MaterialModule } from './shared/material/material.module';
import { SharedModule } from './shared/shared.module';
import { SideNavModule } from './side-nav/side-nav.module';
// import { AuthModule } from './auth/auth.module';

// Effects
import { AuthEffects } from './auth/store/auth.effect';
import { UsersListEffects } from './users/store/users-list/users-list.effects';
import { UsersDetailEffects } from './users/store/users-detail/users-detail.effects';
import { MyAccountEffects } from './my-account/store/my-account.effects';
import { CatalogEffects } from './catalogs/store/catalogs.effects';
import { AccountsListEffects } from './accounts/accounts/accounts-list/store/accounts-list.effects';
import { AccountsDetailEffects } from './accounts/accounts/accounts-detail/store/accounts-detail.effects';
import { ContactsListEffects } from './accounts/contacts/contacts-list/store/contacts-list.effects';
import { ContactsDetailEffects } from './accounts/contacts/contacts-detail/store/contacts-detail.effects';


// Services
import { UsersListService } from './users/services/users-list.service';
import { MyAccountService } from './my-account/services/my-account.service';
import { CatalogsService } from './catalogs/services/catalogs.service';
import { AccountsService } from './accounts/services/accounts.service';

// // Components
// import { AuthComponent } from './auth/auth.component';

@NgModule({
  declarations: [
    AppComponent,
    // AuthComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MaterialModule,
    SharedModule,
    SideNavModule,
    // AuthModule,
    StoreModule.forRoot(appReducers),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    EffectsModule.forRoot([
                            AuthEffects,
                            UsersListEffects,
                            UsersDetailEffects,
                            MyAccountEffects,
                            CatalogEffects,
                            AccountsListEffects,
                            AccountsDetailEffects,
                            ContactsListEffects,
                            ContactsDetailEffects
                          ])
  ],
  providers: [
    AuthEffects,
    UsersListService,
    MyAccountService,
    CatalogsService,
    AccountsService
  ],
  exports: [
    // AuthComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
