import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { SpinnerModule } from './spinner/spinner.module';
import { SnackBarModuleModule } from './snack-bar/snack-bar.module';
import { GlobalSpinnerModule } from './spinner/global-spinner/global-spinner.module';
import { CatchError400Module } from './catch-error-400/catch-error-400.module';
import { HandleSubmitModule } from './handle-submit/handle-submit.module';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    HttpClientModule,
    SpinnerModule,
    GlobalSpinnerModule,
    CatchError400Module,
    HandleSubmitModule
  ],
  exports: [
    CommonModule,
    ReactiveFormsModule,
    HttpClientModule,
    SpinnerModule,
    SnackBarModuleModule,
    GlobalSpinnerModule,
    CatchError400Module,
    HandleSubmitModule
  ]
})

export class SharedModule {}
