<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #sidenav
               [mode]="getSideNavMode()"
               [disableClose]='isSidenavCloseDisabled'
               [opened]="isSidenavOpen"
               *ngIf="isAuth">
    
    <side-nav (closeSidenavEvent)="closeSideNav()"></side-nav>
  </mat-sidenav>
  <mat-sidenav-content >
    <mat-icon *ngIf="!isLoginRoute" (click)="sidenav.toggle()" class="open-sidenav-btn">menu</mat-icon>
    <h1 class="component-title" *ngIf="!isLoginRoute">{{ componentTitle }}</h1>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
<global-spinner *ngIf="!isAuth && !isLoginRoute"></global-spinner>