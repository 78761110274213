import { NgModule } from '@angular/core';
import { CatchError400Service } from './catch-error-400.service';

@NgModule({
  providers: [
    CatchError400Service
  ]
})

export class CatchError400Module {}
