import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, mergeMap, catchError, tap } from 'rxjs/operators';

// import * as action from './incontactsDetailResponseex';
import * as actions from './contacts-detail.actions';

// Service
import { AccountsService } from '../../../services/accounts.service';

@Injectable()
export class ContactsDetailEffects {
  constructor(private actions$: Actions,
              private _accountsService: AccountsService) {}

  getContactsDetail$ = createEffect(() => this.actions$.pipe(
    ofType(actions.contactsDetailRequest),
    mergeMap(({ contactsDetailRequest }) => this._accountsService.getContactsDetail(contactsDetailRequest)
      .pipe(
        map(contactsDetail => actions.contactsDetailSuccess({ contactsDetailResponse: contactsDetail })),
        catchError((err) => of(actions.contactsDetailError({ contactsDetailResponse: err.error })))
      ))
  ));

  // usersListViewState$ = createEffect(() => this.actions$.pipe(
  //   ofType(userActions.usersListView),
  //   mergeMap(({usersListViewRequest}) => of(usersListViewRequest)
  //     .pipe(
  //       map(users => userActions.usersListViewSuccess({ usersListView: users })),
  //       catchError((err) => of(userActions.usersError({ error: err.error })))
  //     ))
  // ));
}
