import { Injectable } from '@angular/core';

@Injectable()
export class SnackBarMessage {
  message: string;

  getMessage() {
    return this.message;
  }

  setMessage(message: string) {
    this.message = message;
  }
}
