import { createAction, props } from '@ngrx/store';

// Models
import { ApiResponseModel } from 'crm-shared/models/api-response/api-response.model';
import { AccountsDetailRequestModel, AccountsDetailResponseModel } from 'crm-shared/models/accounts/accountsDetail.model';
import { ContactsDetailResponseModel } from 'crm-shared/models/accounts/contacts/contactsDetail.model';
import { ContactsCreateRequestModel } from 'crm-shared/models/accounts/contacts/contactsCreate.model';


// Get AccountsDetail
export const accountsDetailRequest = createAction('[Accounts Detail] Accounts Detail Request', props<{ accountsDetailRequest: AccountsDetailRequestModel }>());
export const accountsDetailSuccess = createAction('[Accounts Detail] Accounts Detail Success', props<{ accountsDetailResponse: ApiResponseModel<AccountsDetailResponseModel> }>());
export const accountsDetailError = createAction('[Accounts Detail] Accounts Detail Error', props<{ accountsDetailResponse: ApiResponseModel<any> }>());

// Create ContactInAccountsDetail
export const createContactInAccountsDetailRequest = createAction('[Create Contact In Accounts Detail] Create Contact In Accounts Detail Request', props<{ createContactInAccountsDetailRequest: ContactsCreateRequestModel }>());
export const createContactInAccountsDetailSuccess = createAction('[Create Contact In Accounts Detail] Create Contact In Accounts Detail Success', props<{ createContactInAccountsDetailResponse: ApiResponseModel<ContactsDetailResponseModel> }>());
export const createContactInAccountsDetailError = createAction('[Create Contact In Accounts Detail] Create Contact In Accounts Detail Error', props<{ createContactInAccountsDetailResponse: ApiResponseModel<any> }>());
