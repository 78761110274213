import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, mergeMap, catchError, tap } from 'rxjs/operators';
import { Router } from '@angular/router';

// import * as action from './index';
import * as catalogActions from './catalogs.actions';

// Service
import { CatalogsService } from '../services/catalogs.service';

@Injectable()
export class CatalogEffects {
  constructor(private actions$: Actions,
              private _catalogsService: CatalogsService,
              private router: Router) {}

  getCatalogsInLogin$ = createEffect(() => this.actions$.pipe(
    ofType(catalogActions.catalogsLoginRequest),
    mergeMap(() => this._catalogsService.getAllModels()
      .pipe(
        map(catalogs => catalogActions.catalogsSuccess({ catalogsResponse: catalogs })),
        tap(() => this.navigateInLogin()),
        catchError((err) => of(catalogActions.catalogsError({ error: err.error })))
      )),
  ));

  getCatalogs$ = createEffect(() => this.actions$.pipe(
    ofType(catalogActions.catalogsRequest),
    mergeMap(() => this._catalogsService.getAllModels()
      .pipe(
        map(catalogs => catalogActions.catalogsSuccess({ catalogsResponse: catalogs })),
        catchError((err) => of(catalogActions.catalogsError({ error: err.error })))
      )),
  ));

  // Aqui me queede
  navigateInLogin() {
    this.router.navigate(['my-account']);
  }
}
