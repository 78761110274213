import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

// Interfaces
import { MyAccountRowInterface } from '../interfaces/my-account-row.interface';


// Models
import { ApiResponseModel } from 'crm-shared/models/api-response/api-response.model';
import { MyAccountResponseModel } from 'crm-shared/models/my-account/my-account.model';
import { MyAccountUpdateRequestModel } from 'crm-shared/models/my-account/my-account-update.model';
import { MyAccountUpdatePasswordRequestModel } from 'crm-shared/models/my-account/my-account-update-password.model';
import { GetToken } from 'get-token';

@Injectable()
export class MyAccountService {

  constructor(private http: HttpClient) {}

  getMyAccount(): Observable<ApiResponseModel<MyAccountResponseModel>> {
    const { token } = new GetToken();
    const headers = new HttpHeaders({ Authorization: token });
    return this.http.get<ApiResponseModel<MyAccountResponseModel>>(`${environment.api_url}/users/my-profile`, { headers });
  }

  updateMyAccount(myAccountUpdateRequestModel: MyAccountUpdateRequestModel) {
    const { token } = new GetToken();
    const headers = new HttpHeaders({ Authorization: token });

    return this.http.put<ApiResponseModel<MyAccountResponseModel>>(`${environment.api_url}/users/my-profile`, myAccountUpdateRequestModel, { headers } );
  }

  updateMyAccountPassword(updatePasswordRequest: MyAccountUpdatePasswordRequestModel): Observable<ApiResponseModel<MyAccountResponseModel>> {
    const { token } = new GetToken();
    const headers = new HttpHeaders({ Authorization: token });
    return this.http.put<ApiResponseModel<MyAccountResponseModel>>(`${environment.api_url}/users/my-profile/change-password`,
                                                                    updatePasswordRequest,  { headers });
  }

  myAccountRowData(myAccountResponse: MyAccountResponseModel): MyAccountRowInterface[] {
    return [
      {
        rowIcon: 'edit',
        rowTitle: 'Datos Personales',
        rowAction: 'edit-my-account',
        rowItems: [
          {
            itemIcon: 'person',
            itemLabel: 'Nombre',
            itemData: `${myAccountResponse?.name} ${myAccountResponse?.lastname_1}`,
          },
          {
            itemIcon: 'work',
            itemLabel: 'Departamento',
            itemData: `${myAccountResponse?.department?.name}`,
          },
          {
            itemIcon: 'work',
            itemLabel: 'Puesto',
            itemData: `${myAccountResponse?.position?.name}`,
          },
        ]
      },
      {
        rowIcon: 'edit',
        rowTitle: 'Credenciales',
        rowAction: 'edit-my-password',
        rowItems: [
          {
            itemIcon: 'email',
            itemLabel: 'Email',
            itemData: `${myAccountResponse?.email}`,
          },
          {
            itemIcon: 'lock',
            itemLabel: 'Password',
            itemData: `********`,
          },
        ]
      }
    ];
  }
}
