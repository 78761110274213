import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';


// Models
import { ApiResponseModel } from 'crm-shared/models/api-response/api-response.model';
import { AccountsListResponseModel } from 'crm-shared/models/accounts/accountsList.model';
import { ContactsListResponseModel } from 'crm-shared/models/accounts/contacts/contactsList.model';

import { AccountsDetailResponseModel } from 'crm-shared/models/accounts/accountsDetail.model';
import { ContactsDetailResponseModel } from 'crm-shared/models/accounts/contacts/contactsDetail.model';

import { ContactsCreateRequestModel } from 'crm-shared/models/accounts/contacts/contactsCreate.model';



// Token
import { GetToken } from 'get-token';

@Injectable()
export class AccountsService {

  constructor(private http: HttpClient) {}

  getAccountsList(name = '', page = '', limit = ''): Observable<ApiResponseModel<AccountsListResponseModel[]>> {
    const { token } = new GetToken();
    const headers = new HttpHeaders({ Authorization: token });
    return this.http.get<ApiResponseModel<AccountsListResponseModel[]>>(`${environment.api_url}/accounts?name=${name}&page=${page}&limit=${limit}`, { headers });
  }

  getAccountsDetail(id: string): Observable<ApiResponseModel<AccountsDetailResponseModel>> {
    const { token } = new GetToken();
    const headers = new HttpHeaders({ Authorization: token });
    return this.http.get<ApiResponseModel<AccountsDetailResponseModel>>(`${environment.api_url}/accounts/${id}`, { headers });
  }

  getContactsList(name = '', page = '', limit = ''): Observable<ApiResponseModel<ContactsListResponseModel[]>> {
    const { token } = new GetToken();
    const headers = new HttpHeaders({ Authorization: token });
    return this.http.get<ApiResponseModel<ContactsListResponseModel[]>>(`${environment.api_url}/accounts/contacts?name=${name}&page=${page}&limit=${limit}`, { headers });
  }

  getContactsDetail(id: string): Observable<ApiResponseModel<ContactsDetailResponseModel>> {
    const { token } = new GetToken();
    const headers = new HttpHeaders({ Authorization: token });
    return this.http.get<ApiResponseModel<ContactsDetailResponseModel>>(`${environment.api_url}/accounts/contacts/${id}`, { headers });
  }

  createContact(contact: ContactsCreateRequestModel): Observable<ApiResponseModel<ContactsDetailResponseModel>> {
    console.log('llega al servicio', contact);
    const { token } = new GetToken();
    const headers = new HttpHeaders({ Authorization: token });
    return this.http.post<ApiResponseModel<ContactsDetailResponseModel>>(`${environment.api_url}/accounts/contacts/`, contact, { headers });
  }
}
