import { NgModule } from '@angular/core';
import { MaterialModule } from '../material/material.module';
import { SpinnerComponent } from './spinner.component';

@NgModule({
  imports: [
    MaterialModule
  ],
  declarations: [
    SpinnerComponent
  ],
  exports: [
    SpinnerComponent
  ]
})

export class SpinnerModule {}
