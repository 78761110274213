import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, mergeMap, catchError, tap } from 'rxjs/operators';

// import * as action from './index';
import * as myAccountActions from './my-account.actions';

// Service
import { MyAccountService } from '../services/my-account.service';

@Injectable()
export class MyAccountEffects {
  constructor(private actions$: Actions,
              private _myAccountService: MyAccountService) {}

  getMyAccount$ = createEffect(() => this.actions$.pipe(
    ofType(myAccountActions.myAccountRequest),
    mergeMap(() => this._myAccountService.getMyAccount()
      .pipe(
        map(myAccount => myAccountActions.myAccountSuccess({ myAccountResponse: myAccount })),
        catchError((err) => of(myAccountActions.myAccountError({ error: err.error })))
      ))
  ));

  myAccountUpdate$ = createEffect(() => this.actions$.pipe(
    ofType(myAccountActions.myAccountUpdateRequest),
    mergeMap((action) => this._myAccountService.updateMyAccount(action.myAccountUpdateRequestModel)
      .pipe(
        map(myAccount => myAccountActions.myAccountUpdateSuccess({ myAccountResponse: myAccount })),
        catchError((err) => of(myAccountActions.myAccountUpdateError({ myAccountResponseError: err.error })))
      ))
  ));

  myAccountUpdatePassword$ = createEffect(() => this.actions$.pipe(
    ofType(myAccountActions.myAccountUpdatePasswordRequest),
    mergeMap((action) => this._myAccountService.updateMyAccountPassword(action.myAccountUpdatePasswordRequestModel)
      .pipe(
        map(myAccount => myAccountActions.myAccountUpdatePasswordSuccess({ myAccountResponse: myAccount })),
        catchError((err) => of(myAccountActions.myAccountUpdatePasswordError({ myAccountResponseError: err.error })))
      ))
  ));
}
