import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { GetToken } from 'get-token';

import { ApiResponseModel } from 'crm-shared/models/api-response/api-response.model';
import { CatalogResponseModel } from 'crm-shared/models/catalog/catalog.model';

import { AppState } from '../../app.interface';
import { Store } from '@ngrx/store';

import * as catalogsActions from '../store/catalogs.actions';


@Injectable({
  providedIn: 'root'
})
export class CatalogsService {

  constructor(private http: HttpClient,
              private store: Store<AppState>) {

    this.store.select('catalogs').subscribe(res => {
      this.catalogs = res;
    });
  }

  catalogs: ApiResponseModel<CatalogResponseModel[]> = {
    isLoading: false,
    isLoaded: false,
    response: null,
    message: null,
    statusCode: null,
  };

  getAllModels(): Observable<ApiResponseModel<CatalogResponseModel[]>> {
    const { token } = new GetToken();
    const headers = new HttpHeaders({ Authorization: token });
    return this.http.get<any>(`${environment.api_url}/models`, { headers });
  }
}
