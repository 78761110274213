import { createAction, props } from '@ngrx/store';

// Models
import { ApiResponseModel, ActionType } from 'crm-shared/models/api-response/api-response.model';
import { MyAccountResponseModel } from 'crm-shared/models/my-account/my-account.model';
import { MyAccountUpdatePasswordRequestModel } from 'crm-shared/models/my-account/my-account-update-password.model';
import { MyAccountUpdateRequestModel } from 'crm-shared/models/my-account/my-account-update.model';


export const myAccountRequest = createAction('[My-Account] My-Account Request');
export const myAccountSuccess = createAction('[My-Account] My-Account Success',
                                props<{ myAccountResponse: ApiResponseModel<MyAccountResponseModel> }>());
export const myAccountError =   createAction('[My-Account] My-Account Error',
                                props<{ error: any }>());

export const myAccountUpdateRequest = createAction('[My-Account-Update] My-Account-Update Request',
                                      props<{ myAccountUpdateRequestModel: MyAccountUpdateRequestModel }>());
export const myAccountUpdateSuccess = createAction('[My-Account-Update] My-Account-Update Success',
                                      props<{ myAccountResponse: ApiResponseModel<MyAccountResponseModel> }>());
export const myAccountUpdateError =   createAction('[My-Account-Update] My-Account-Update Error',
                                      props<{ myAccountResponseError: any }>());


export const myAccountUpdatePasswordRequest = createAction('[My-Account-Update-Password] My-Account-Update-Password Request',
                                              props<{ myAccountUpdatePasswordRequestModel: MyAccountUpdatePasswordRequestModel }>());
export const myAccountUpdatePasswordSuccess = createAction('[My-Account-Update-Password] My-Account-Update-Password Success',
                                              props<{ myAccountResponse: ApiResponseModel<MyAccountResponseModel> }>());
export const myAccountUpdatePasswordError =   createAction('[My-Account-Update-Password] My-Account-Update-Password Error',
                                              props<{ myAccountResponseError: any }>());
