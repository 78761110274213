import { createReducer, on } from '@ngrx/store';
import * as actions from './catalogs.actions';

// Models
import { ApiResponseModel } from 'crm-shared/models/api-response/api-response.model';
import { CatalogResponseModel } from 'crm-shared/models/catalog/catalog.model';

export const initialState: ApiResponseModel<CatalogResponseModel[]> = {
  statusCode: null,
  message: null,
  response: [],
  additionalResponse: null,
  isLoading: false,
  isLoaded: false,
};


const _catalogsListReducer = createReducer(initialState,
  on(actions.catalogsLoginRequest, (state) => ({
    ...state,
    isLoading: true,
    isLoaded: false,
  })),
  on(actions.catalogsRequest, (state) => ({
    ...state,
    isLoading: true,
    isLoaded: false,
  })),
  on(actions.catalogsSuccess, (state, {catalogsResponse}) => ({
    statusCode: catalogsResponse.statusCode,
    message: catalogsResponse.message,
    response: catalogsResponse.response,
    isLoading: false,
    isLoaded: true,
  })),
  on(actions.catalogsError, (state, { error }) => ({
    ...state,
    error,
    isLoaded: false,
    isLoading: false,
  })),
);

export function catalogsReducer(state, action) {
  return _catalogsListReducer(state, action);
}
