import { createReducer, on } from '@ngrx/store';
import * as actions from './auth.actions';

// Models
import { AuthResponseModel } from 'crm-shared/models/auth/auth.model';

export const initialState: AuthResponseModel = {
  access_token: null,
  user: {
    id: null,
    email: null,
    lastname: null,
    name: null,
    profile_img: null,
    role: null,
    short_name: null,
  },
  isLoading: false,
  isLoaded: false,
  error: null,
};

const _authReducer = createReducer(initialState,
  on(actions.login, state => ({
    ...state,
    isLoading: true,
    isLoaded: false,
    error: null
  })),
  on(actions.loginSuccess, (state, {userLoggedIn}) => ({
    access_token: userLoggedIn.access_token,
    user: userLoggedIn.user,
    isLoading: false,
    isLoaded: true,
    error: null
  })),
  on(actions.loginError, (state, { error }) => ({
    access_token: null,
    user: initialState.user,
    error,
    isLoaded: false,
    isLoading: false,
  })),
);

export function authReducer(state, action) {
  return _authReducer(state, action);
}
