import { createAction, props } from '@ngrx/store';

// Models
import { ApiResponseModel } from 'crm-shared/models/api-response/api-response.model';
import { CatalogResponseModel } from 'crm-shared/models/catalog/catalog.model';

export const catalogsLoginRequest = createAction('[Catalogs] Catalogs Login Request');
export const catalogsRequest = createAction('[Catalogs] Catalogs Request');
export const catalogsSuccess = createAction('[Catalogs] Catalogs Success',
                               props<{ catalogsResponse: ApiResponseModel<CatalogResponseModel[]> }>());
export const catalogsError = createAction('[Catalogs] Catalogs Error', props<{ error: any }>());
