import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, pipe } from 'rxjs';
import { map, mergeMap, catchError, tap } from 'rxjs/operators';

// import * as action from './index';
import * as actions from './accounts-list.actions';

// Service
import { AccountsService } from '../../../services/accounts.service';

@Injectable()
export class AccountsListEffects {
  constructor(private actions$: Actions,
              private _accountsService: AccountsService) {}

  getUsers$ = createEffect(() => this.actions$.pipe(
    ofType(actions.accountsListRequest),
    mergeMap(({ accountsListRequest }) => this._accountsService.getAccountsList(accountsListRequest.name, accountsListRequest.page, accountsListRequest.limit)
      .pipe(
        map(accounts => actions.accountsListSuccess({ accountsListResponse: accounts })),
        catchError((err) => of(actions.accountsListError({ accountsListResponse: err.error })))
      ))
  ));

  // usersListViewState$ = createEffect(() => this.actions$.pipe(
  //   ofType(userActions.usersListView),
  //   mergeMap(({usersListViewRequest}) => of(usersListViewRequest)
  //     .pipe(
  //       map(users => userActions.usersListViewSuccess({ usersListView: users })),
  //       catchError((err) => of(userActions.usersError({ error: err.error })))
  //     ))
  // ));
}
