import { ActionReducerMap } from '@ngrx/store';
import * as authReducers from './auth/store/auth.reducers';
import * as usersListReducers from './users/store/users-list/users-list.reducers';
import * as usersDetailReducers from './users/store/users-detail/users-detail.reducers';
import * as myAccountReducers from './my-account/store/my-account.reducers';
import * as catalogsReducers from './catalogs/store/catalogs.reducers';

import { AppState } from './app.interface';

export const appReducers: ActionReducerMap<AppState> = {
  auth: authReducers.authReducer,
  // usersList: usersListReducers.usersListReducer,
  // usersDetail: usersDetailReducers.usersDetailReducer,
  // usersListView: usersListReducers.usersListViewReducer,
  // myAccount: myAccountReducers.myAccountReducer,
  catalogs: catalogsReducers.catalogsReducer
};
