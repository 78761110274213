import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, mergeMap, catchError, tap } from 'rxjs/operators';

// import * as action from './index';
import * as userActions from './users-detail.actions';

// Service
import { UsersListService } from '../../services/users-list.service';

@Injectable()
export class UsersDetailEffects {
  constructor(private actions$: Actions,
              private _usersListService: UsersListService) {}

  getUser$ = createEffect(() => this.actions$.pipe(
    ofType(userActions.userDetailRequest),
    mergeMap(({userDetailRequest}) => this._usersListService.getUserById(userDetailRequest.id)
      .pipe(
        map(user => userActions.userDetailSuccess({ userDetailResponse: user })),
        catchError((err) => of(userActions.userDetailError({ userDetailResponse: err.error })))
      ))
  ));

  updateUser$ = createEffect(() => this.actions$.pipe(
    ofType(userActions.userDetailUpdateRequest),
    mergeMap(({userDetailUpdateRequest}) => this._usersListService.updateUser(userDetailUpdateRequest)
      .pipe(
        map(user => userActions.userDetailUpdateSuccess({ userDetailUpdateResponse: user })),
        catchError((err) => of(userActions.userDetailUpdateError({ userDetailUpdateResponse: err.error })))
      ))
  ));
}
