import { createAction, props } from '@ngrx/store';

// Models
import { ApiResponseModel } from 'crm-shared/models/api-response/api-response.model';
import { AccountsListResponseModel, AccountsListRequestModel } from 'crm-shared/models/accounts/accountsList.model';
// import { UserResponseModel } from 'crm-shared/models/user/user.model';
// import { UserUpdateRequestModel } from 'crm-shared/models/user/user-update.model';
// import { UserCreateRequestModel } from 'crm-shared/models/user/user-create.model';

// Front End Models
// import { UsersListViewState } from '../../../shared/front-end-models/users-list-view.model';

// Filters
// class UserListRequestModel {
//   page: string;
//   limit: string;
//   dept: string;
//   position: string;
//   name: string;
// }



// Get UsersList
export const accountsListRequest = createAction('[Accounts List] Accounts List Request', props<{ accountsListRequest: AccountsListRequestModel }>());
export const accountsListSuccess = createAction('[Accounts List] Accounts List Success', props<{ accountsListResponse: ApiResponseModel<AccountsListResponseModel[]> }>());
export const accountsListError = createAction('[Accounts List] Accounts List Error', props<{ accountsListResponse: ApiResponseModel<any> }>());

