import { createAction, props } from '@ngrx/store';

// Models
import { ApiResponseModel } from 'crm-shared/models/api-response/api-response.model';
import { UserResponseModel } from 'crm-shared/models/user/user.model';
import { UserUpdateRequestModel } from 'crm-shared/models/user/user-update.model';
import { UserCreateRequestModel } from 'crm-shared/models/user/user-create.model';

// Front End Models
import { UsersListViewState } from '../../../shared/front-end-models/users-list-view.model';

class UserListRequestModel {
  page: string;
  limit: string;
  dept: string;
  position: string;
  name: string;
}



// Get UsersList
export const usersRequest = createAction('[Users List] Users Request', props<{ userListRequest: UserListRequestModel }>());
export const usersSuccess = createAction('[Users List] Users Success', props<{ usersResponse: ApiResponseModel<UserResponseModel[]> }>());
export const usersError = createAction('[Users List] Users Error', props<{ error: any }>());

// Create User
export const userCreateRequest = createAction('[User Create] User Create Request', props<{ userCreateRequest: UserCreateRequestModel }>());
export const userCreateSuccess = createAction('[User Create] User Create Success', props<{ userResponse: ApiResponseModel<UserResponseModel> }>());
export const userCreateError = createAction('[User Create] User Create Error', props<{ userResponse: ApiResponseModel<UserResponseModel> }>());

// Update Users is_active property
export const userUpdateActiveRequest = createAction('[User Update Active Status] User Update Active Request', props<{ userUpdateActiveRequest: UserUpdateRequestModel }>());
export const userUpdateActiveSuccess = createAction('[User Update Active Status] User Update Active Success', props<{ userResponse: ApiResponseModel<UserResponseModel> }>());
export const userUpdateActiveError = createAction('[User Update Active Status] User Update Active Error', props<{ error: any }>());

// Users List View State
export const usersListView = createAction('[Users List View Request] Users List View Request', props<{ usersListViewRequest: UsersListViewState }>());
export const usersListViewSuccess = createAction('[Users List View Sucess] Users List View Sucess', props<{ usersListView: UsersListViewState }>());
