import { NgModule } from '@angular/core';
import { HandleSubmitService } from './handle-submit.service';

@NgModule({
  providers: [
    HandleSubmitService
  ]
})

export class HandleSubmitModule {}
