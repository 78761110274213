import { Component, OnInit } from '@angular/core';
import { SnackBarMessage } from './snack-bar-message.service';


@Component({
  selector: 'app-snack-bar',
  templateUrl: 'snack-bar.component.html',
  styleUrls: ['snack-bar.component.scss']
})

export class SnackBarComponent implements OnInit {

  message: string;
  constructor(private _sbMessage: SnackBarMessage) {}

  ngOnInit() {
    this.message = this._sbMessage.getMessage();
  }


}
