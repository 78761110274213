import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

// Models
import { AuthResponseModel, AuthRequestModel } from 'crm-shared/models/auth/auth.model';
import { Observable } from 'rxjs';

// Services
import { CatalogsService } from '../../catalogs/services/catalogs.service';

// Store
import { Store } from '@ngrx/store';
import { AppState } from '../../app.interface';
import * as catalogsActions from '../../catalogs/store/catalogs.actions';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient,
              private _catalogsService: CatalogsService,
              private store: Store) {}

  login(username: string, password: string): Observable<AuthResponseModel> {
    const body: AuthRequestModel = {
      username,
      password
    };

    return this.http.post<AuthResponseModel>(`${environment.api_url}/auth/login`, body);
  }

  getToken() {
    const token = localStorage.getItem('access_token');
    return (token) ? token : false;
  }

  getUserInfo() {
    const userInfo = localStorage.getItem('user');
    return (userInfo) ? userInfo : false;
  }

  get catalogs(): boolean {
    return this._catalogsService.catalogs?.isLoaded;
  }

  get isAuth(): boolean {
    // const token = localStorage.getItem('access_token');
    // const user = localStorage.getItem('user');

    // console.log('isAuthService', this.catalogs);


    return (this.getToken() && this.getUserInfo() && this.catalogs) ? true : false;

    // if (this.getToken() && this.getUserInfo()) {
    //   console.log('isAuth true');
    //   return true;
    // } else {
    //   console.log('servicio entra a false');
    //   // this.isAuth = false;
    //   return false;
    // }
  }
}
