import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, pipe } from 'rxjs';
import { map, mergeMap, catchError, tap } from 'rxjs/operators';

// import * as action from './index';
import * as actions from './contacts-list.actions';

// Service
import { AccountsService } from '../../../services/accounts.service';

@Injectable()
export class ContactsListEffects {
  constructor(private actions$: Actions,
              private _accountsService: AccountsService) {}

  getUsers$ = createEffect(() => this.actions$.pipe(
    ofType(actions.contactsListRequest),
    mergeMap(({ contactsListRequest }) => this._accountsService.getContactsList(contactsListRequest.name, contactsListRequest.page, contactsListRequest.limit)
      .pipe(
        map(contacts => actions.contactsListSuccess({ contactsListResponse: contacts })),
        catchError((err) => of(actions.contactsListError({ contactsListResponse: err.error })))
      ))
  ));

  // usersListViewState$ = createEffect(() => this.actions$.pipe(
  //   ofType(userActions.usersListView),
  //   mergeMap(({usersListViewRequest}) => of(usersListViewRequest)
  //     .pipe(
  //       map(users => userActions.usersListViewSuccess({ usersListView: users })),
  //       catchError((err) => of(userActions.usersError({ error: err.error })))
  //     ))
  // ));
}
