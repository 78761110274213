import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { SnackBarComponent } from './snack-bar.component';
import { SnackBarMessage } from './snack-bar-message.service';

@Injectable()
export class SnackBarService {

  constructor(private _sb: MatSnackBar,
              private _sbMessage: SnackBarMessage) {}

  snackBarMessage: string;

  openSnackBar(message: string) {
    this._sbMessage.setMessage(message);
    this._sb.openFromComponent(SnackBarComponent,
                              {
                                verticalPosition: 'top',
                                horizontalPosition: 'right',
                                duration: 3000,
                                panelClass: ['snack-bar-style']
                              });
  }
}
