import { NgModule } from '@angular/core';

// Components
import { SnackBarComponent } from './snack-bar.component';

// Services
import { SnackBarService } from './snack-bar.service';
import { SnackBarMessage } from './snack-bar-message.service';


@NgModule({
  declarations: [
    SnackBarComponent
  ],

  providers: [
    SnackBarService,
    SnackBarMessage
  ]
})
export class SnackBarModuleModule { }
