import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, mergeMap, catchError, tap, switchMap } from 'rxjs/operators';

// import * as action from './index';
import * as actions from './accounts-detail.actions';
import * as contactsListActions from '../../../contacts/contacts-list/store/contacts-list.actions';

// Service
import { AccountsService } from '../../../services/accounts.service';

@Injectable()
export class AccountsDetailEffects {
  constructor(private actions$: Actions,
              private _accountsService: AccountsService) {}

  getAccountsDetail$ = createEffect(() => this.actions$.pipe(
    ofType(actions.accountsDetailRequest),
    mergeMap(({ accountsDetailRequest }) => this._accountsService.getAccountsDetail(accountsDetailRequest.id)
      .pipe(
        map(accountsDetail => actions.accountsDetailSuccess({ accountsDetailResponse: accountsDetail })),
        catchError((err) => of(actions.accountsDetailError({ accountsDetailResponse: err.error })))
      ))
  ));

  createContactInAccountsDetail$ = createEffect(() => this.actions$.pipe(
    ofType(actions.createContactInAccountsDetailRequest),
    mergeMap(({ createContactInAccountsDetailRequest }) => this._accountsService.createContact(createContactInAccountsDetailRequest)
      .pipe(
        // map(contactDetail => actions.createContactInAccountsDetailSuccess({ createContactInAccountsDetailResponse: contactDetail })),
        switchMap((contactDetail) => [actions.createContactInAccountsDetailSuccess({ createContactInAccountsDetailResponse: contactDetail }), contactsListActions.contactsListRefresh()]),
        catchError((err) => of(actions.createContactInAccountsDetailError({ createContactInAccountsDetailResponse: err.error })))
      )
    )
  ));

  // usersListViewState$ = createEffect(() => this.actions$.pipe(
  //   ofType(userActions.usersListView),
  //   mergeMap(({usersListViewRequest}) => of(usersListViewRequest)
  //     .pipe(
  //       map(users => userActions.usersListViewSuccess({ usersListView: users })),
  //       catchError((err) => of(userActions.usersError({ error: err.error })))
  //     ))
  // ));
}
