import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';


// Models
import { ApiResponseModel } from 'crm-shared/models/api-response/api-response.model';
import { UserResponseModel } from 'crm-shared/models/user/user.model';
import { UserUpdateRequestModel } from 'crm-shared/models/user/user-update.model';
import { UserCreateRequestModel } from 'crm-shared/models/user/user-create.model';


// Token
import { GetToken } from 'get-token';

@Injectable()
export class UsersListService {

  constructor(private http: HttpClient) {}

  getUsers(page = '', limit = '', dept = '', position = '', name = ''): Observable<ApiResponseModel<UserResponseModel[]>> {
    const { token } = new GetToken();
    const headers = new HttpHeaders({ Authorization: token });
    return this.http.get<ApiResponseModel<UserResponseModel[]>>(`${environment.api_url}/users?page=${page}&limit=${limit}&department=${dept}&position=${position}&name=${name}`, { headers });
  }

  getUserById(userId: string) {
    const { token } = new GetToken();
    const headers = new HttpHeaders({ Authorization: token });
    return this.http.get<ApiResponseModel<UserResponseModel>>(`${environment.api_url}/users/${userId}`, { headers });
  }

  createUser(userCreateRequest: UserCreateRequestModel): Observable<ApiResponseModel<UserResponseModel>> {
    const { token } = new GetToken();
    const headers = new HttpHeaders({ Authorization: token });
    const userCreateBody: UserCreateRequestModel = userCreateRequest;
    return this.http.post<ApiResponseModel<UserResponseModel>>(`${environment.api_url}/users`, userCreateBody , { headers });
  }

  updateUser(userUpdateRequest: UserUpdateRequestModel): Observable<ApiResponseModel<UserResponseModel>> {
    const { token } = new GetToken();
    const headers = new HttpHeaders({ Authorization: token });
    const userUpdateBody: UserUpdateRequestModel = userUpdateRequest;
    return this.http.put<ApiResponseModel<UserResponseModel>>(`${environment.api_url}/users/${userUpdateBody._id}`, userUpdateBody , { headers });
  }
}
